import { createContext, useEffect, useReducer, useState } from 'react';
import { useAuthContext } from '../hooks/contextHooks/useAuthContext';
import axios from 'axios';
import { fetchLocation } from '../functions/fetchers/fetchLocation';

export const SettingsContext = createContext();

export const settingsReducer = (state,action) => {
    switch(action.type){
        case 'GET_MEASUREMENT_SYSTEM':
            return { ...state, measurementSystem: state.measurementSystem }
        case 'SET_MEASUREMENT_SYSTEM':
            return {... state, measurementSystem: action.payload}
        default:
            return state;
    }
}

export const SettingsContextProvider = ({children}) => {

    const [location, setLocation] = useState({});
    const [state,settingsDispatch] = useReducer(settingsReducer, {
        measurementSystem: 'metric'
    });


    useEffect(() => {
        const loc = fetchLocation();
        setLocation(loc);
    }, []);

    useEffect(() => {
        if(location.country === 'USA' || location.country === 'Canada'){
            settingsDispatch({type:'SET_MEASUREMENT_SYSTEM',payload:'ft'})
        }
    },[location]);
    
    return (
        <SettingsContext.Provider value={{...state,settingsDispatch}}>
            {children}
        </SettingsContext.Provider>
    )
}