import { useEffect, useState } from "react"
import { useAuthContext } from "../contextHooks/useAuthContext";
import axios from "axios";
import config from '../../config/config.json';
import { fetchLocation } from "../../functions/fetchers/fetchLocation";

export const useAuthForm = () => {
    // Hooks
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [messageVerification, setMessageVerification] = useState("");
    const [timer, setTimer] = useState(-1);
    // Context
    const { dispatch } = useAuthContext();

    const refresh = () => {
        setError(false);
        setMessage("");
    }

    useEffect(() => {
        if (timer <= 0) {
            return;
        }
        // Decrease the timer every second
        const timerInterval = setInterval(() => {
            setTimer(prevSeconds => prevSeconds - 1);
        }, 1000);

        // Clean up the interval when the component is unmounted or when the timer reaches 0
        return () => clearInterval(timerInterval);
    }, [timer])

    // Functions
    const googleLogin = async (accessToken) => {
        refresh();
        const token = accessToken.access_token;
        try {
            const response = await axios.post(config.REACT_APP_GUSER_INFO, {
                access_token: token,
            });

            const countryDatas = await fetchLocation();

            const res = response.data;
            const entryUrl = localStorage.getItem('entryUrl') || "";
            console.log(countryDatas,"hello there")
            const result = await axios.post(config.REACT_APP_API_MAIN_ROUTE + '/auth/google-sign-in', {
                email: res.email, 
                user_id: res.user_id, 
                entry_url:entryUrl, 
                country: countryDatas.country || "",
                city: countryDatas.city || ""
            })
            // Dispatch
            if (!res.verified_email) {
                setError(true);
                setMessage("The user is not a verified Gmail account")
            }
            else {
                localStorage.setItem('user', JSON.stringify(result.data));
                dispatch({ type: 'LOGIN', payload: result.data });
            }
            // You can now use the ID Token as needed
        } catch (error) {
            setError(true);
            setMessage("Connection Error")
            console.error('Error exchanging access token for ID Token:', error.message);
        }
    }


    const sendVerificationCode = async (email) => {
        try {
            const sendResult = await axios.post(config.REACT_APP_API_MAIN_ROUTE + "/auth/sign-in/send-code", { email: email });
            setMessageVerification(true);
            if(!sendResult.data.success){
                setError(true);
                setMessage("Could not send the Verification code to the email");
            }
            setTimer(180);
        } catch (e) {
            console.log(e);
            setError(true);
            setMessage("Could not send the Verification code to the email");
        }

    }

    const emailLogin = async (email, code) => {
        refresh();
        let emailArr = email.split("@");
        if (emailArr.length <= 1) {
            setError(true);
            setMessage("The email must include @");
        }
        else if (!messageVerification) {
            // Step 1 -- Send Verification Code to the email via API
            await sendVerificationCode(email);
        }
        else {
            // Step 2 -- Verify the Email that has arrived in the limited time
            try {
                const entry_url = localStorage.getItem('entryUrl') || "";
                const countryDatas = await fetchLocation();
                const verifyResult = await axios.post(config.REACT_APP_API_MAIN_ROUTE + "/auth/sign-in/verify-code", {
                    email: email,
                    enteredCode: code,
                    entry_url : entry_url,
                    country: countryDatas.country || "",
                    city: countryDatas.city || ""
                });
                localStorage.setItem('user', JSON.stringify(verifyResult.data));
                dispatch({ type: 'LOGIN', payload: verifyResult.data });
            }
            catch (e) {
                setError(true);
                setMessage("The Sign-in failed");
            }
        }

    }

    return { googleLogin, emailLogin, error, message, messageVerification, timer, sendVerificationCode };
}